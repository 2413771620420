import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import EditOrganisationNotificationSettingsForm from '../components/EditOrganisationNotificationSettingsForm';
import EditOrganisationNotificationEmailSettingsForm from '../components/EditOrganisationNotificationEmailSettingsForm';

import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './OrganisationSettings.css';

class OrganisationSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            organisation: {
                name: ''
            },
            preferences: {
                emails: []
                // id: -1,
                // enabled: false,
                // interval: 'DAILY'
            }
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
    }

    loadOrganisationInformation() {

        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation: response.data.organisation
                });

                self.loadNotificationPropertiesInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    onRefreshRequired() {
        console.log(`Refresh Required!`);
        this.loadNotificationPropertiesInformation();
    }

    onRulesPressed(emailId) {
        const { uuid } = this.props.match.params;
        this.props.history.push(`/organisations/${uuid}/emails/${emailId}/rules`);
    }

    loadNotificationPropertiesInformation() {

        var self = this;
        
        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}/notificationpreferences`, {})
            .then(function(response) {
                self.setState({
                    preferences: response.data.preferences[0]
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const {
            uuid
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'OrganisationSettings.title'
        )}`;

        return (
            <div className="OrganisationSettings">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('OrganisationSettings.title')}
                                    {' - '}
                                    <small>
                                        {this.state.organisation.name}
                                    </small>
                                </h1>
                                <ol className="breadcrumb">

                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-cog" />{' '}
                                                {t('OrganisationSettings.title')}
                                            </li>{' '}


                                </ol>

                                <Panel id="detail-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'OrganisationSettings.notifications_title'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <EditOrganisationNotificationSettingsForm
                                            organisation={this.state.organisation}
                                            preferences={this.state.preferences}
                                        />
                                    </Panel.Body>
                                </Panel>

                                <Panel id="detail-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'OrganisationSettings.notifications_emails_title'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <EditOrganisationNotificationEmailSettingsForm
                                            organisation={this.state.organisation}
                                            preferences={this.state.preferences}
                                            emails={this.state.emails}
                                            onRefreshRequired={this.onRefreshRequired.bind(this)}
                                            onRulesPressed={this.onRulesPressed.bind(this)}
                                        />
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(OrganisationSettings);
