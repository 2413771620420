import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';

import EditUserTwoFactorAuthenticationForm from '../components/EditUserTwoFactorAuthenticationForm';

import axios from 'axios';
import { Panel, Col, Row } from 'react-bootstrap';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './UserSettings.css';

class UserSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
    }

    loadOrganisationInformation() {

        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation: response.data.organisation
                });

                self.loadNotificationPropertiesInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    onRefreshRequired() {
        console.log(`Refresh Required!`);
        this.loadNotificationPropertiesInformation();
    }

    onRulesPressed(emailId) {
        const { uuid } = this.props.match.params;
        this.props.history.push(`/organisations/${uuid}/emails/${emailId}/rules`);
    }

    loadNotificationPropertiesInformation() {

        var self = this;
        
        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}/notificationpreferences`, {})
            .then(function(response) {
                self.setState({
                    preferences: response.data.preferences[0]
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        const {
            uuid
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'UserSettings.title'
        )}`;

        let currentOrganisationUUID = this.props.currentOrganisation.uuid;

        return (
            <div className="UserSettings">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('UserSettings.title')}
                                    {' - '}
                                    <small>
                                    {this.state.user.firstName}{' '}
                                    {this.state.user.lastName}
                                    </small>
                                </h1>
                                <ol className="breadcrumb">

                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${currentOrganisationUUID}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-cog" />{' '}
                                                {t('UserSettings.title')}
                                            </li>{' '}
                                </ol>

                                <Panel id="detail-style">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            {t(
                                                'UserSettings.twoFactorAuthentication'
                                            )}
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        { this.state.user.TwoFAEnabled ? ("Two factor authentication is already enabled for this account") : (<EditUserTwoFactorAuthenticationForm
                                            user={this.state.user}
                                            userHasAuthenticated={this.props.userHasAuthenticated}
                                            history={this.props.history}
                                        />)}
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(UserSettings);
