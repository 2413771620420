import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './TooltipButton.css';

export default ({
    className = '',
    tooltip = '',
    disabled = false,
    placement = 'top',
    delayShow = 300,
    delayHide = 150,
    ...props
}) => (
    <OverlayTrigger
        placement={placement}
        delayShow={delayShow}
        delayHide={delayHide}
        overlay={
            disabled ? <></> : (
                <Tooltip id="tooltip">
                    <strong>{tooltip}</strong>
                </Tooltip>
            )
        }
        trigger={disabled ? [] : ['hover', 'focus']}
    >
        <span className="d-inline-block">
            <Button disabled={disabled} className={`TooltipButton ${className}`} {...props} />
        </span>
    </OverlayTrigger>
);
