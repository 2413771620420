import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import config from '../config';
import DashboardTile from '../components/DashboardTile';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Panel, Col, Row, Button, FormControl } from 'react-bootstrap';

import { translate } from 'react-i18next';

import './OrganisationPlayer.css';

class OrganisationPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            player: { name: ' ', uuid: '' },
            statistics: { attributes: 0, pages: 0 }
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.loadPlayerFromServer();
        this.loadStatisticsFromServer();
    }

    loadStatisticsFromServer() {
        var self = this;

        const { uuid, playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}/statistics`, {})
            .then(function(response) {
                self.setState({
                    statistics: response.data.statistics
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { uuid, playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player: response.data.player
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'OrganisationPlayer.title'
        )}`;

        const user = this.state.user;
        const { uuid, playerUUID } = this.props.match.params;

        return (
            <div className="OrganisationPlayer">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {this.state.player.name}{' '}
                                    <small>
                                        {this.state.organisation_name}
                                    </small>
                                    <>
                                    <a
                                        id="previewButton"
                                        type="submit"
                                        className="btn btn-success header-button"
                                        target="_blank"
                                        href={`${
                                            config.playersBaseURL
                                        }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                    >
                                        <em className="fa fa-eye fa-fw" />{' '}
                                        {t('Player.preview_player')}
                                    </a>

                                    <a
                                        id="previewButton"
                                        type="submit"
                                        className="btn btn-warning header-button"
                                        target="_blank"
                                        href={`${
                                            config.playersBaseURL
                                        }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                    >
                                        <em className="fa fa-eye fa-fw" />{' '}
                                        {t('Player.preview_player_all')}
                                    </a>

                                    </>
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/players`}
                                        >
                                            {t('SideNavigation.players')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-tv" />{' '}
                                        {t('OrganisationPlayer.title')}
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                        <Row>
                            <DashboardTile
                                title={t('SideNavigation.pages')}
                                count={this.state.statistics.pages}
                                buttonText={t('View')}
                                icon="fa fa-file fa-5x"
                                colour="red"
                                link={`/organisations/${uuid}/players/${playerUUID}/pages`}
                            />
                            {this.state.userIsAuthorized(user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin'
                            ]) ? (
                                <DashboardTile
                                    title={t('SideNavigation.attributes')}
                                    count={this.state.statistics.attributes}
                                    buttonText={t('View')}
                                    icon="fa fa-tags fa-5x"
                                    colour="yellow"
                                    link={`/organisations/${uuid}/players/${playerUUID}/attributes`}
                                />
                            ) : null}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(OrganisationPlayer);
