import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './EditUserTwoFactorAuthenticationForm.css';

class EditUserTwoFactorAuthenticationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            secret: '',
            token: '',
            qrCode: '',
            user: {
                TwoFAEnabled: false
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.state.user) {
            if (nextProps.organisation) {
                this.setState({ user: nextProps.user });
            }
        }
    }

    componentDidMount() {
        this.generateSecret();
    }

    generateSecret() {
        var self = this;

        axios
            .post(`${config.baseURL}/auth/generateSecret`, {})
            .then(function(response) {
                console.log(`Response: ${JSON.stringify(response.data)}`);
                console.log(`QR: ${response.data.qrCode}`);
                self.setState({ qrCode: response.data.qrCode, secret: response.data.secret });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/auth/verifySecretSetup`, {
                    token: this.state.token            
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditUserTwoFactorAuthenticationForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditUserTwoFactorAuthenticationForm.saved_message'
                            )}`
                        }
                    }, () => {
                        // Navigate back to login after 2 seconds
                        setTimeout(() => {
                         
                            axios
                            .get(`${config.baseURL}/auth/logout`)
                            .then(function(response) {
                                self.props.userHasAuthenticated(false, {
                                    isAuthenticated: false,
                                    isEngineer: false
                                });
                                self.props.history.push('/login');
                            })
                            .catch(function(error) {
                                console.log(error);
                            });

                        }, 2000);

                        
                      });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditUserTwoFactorAuthenticationForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditUserTwoFactorAuthenticationForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditUserTwoFactorAuthenticationForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditUserTwoFactorAuthenticationForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>

                    { this.state.saved ? (null) : (<Fragment>
                    <img
                        src={this.state.qrCode}
                        alt="logo"
                        className="center-block img-responsive"
                        style={{ width: 212, height: 212 }}
                    />
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="token" bsSize="sm">
                                <ControlLabel>
                                    {t('EditUserTwoFactorAuthenticationForm.Token')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.token}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateGlobalPageForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'EditUserTwoFactorAuthenticationForm.verify_secret'
                                    )}`}
                                    loadingText={`${t(
                                        'EditUserTwoFactorAuthenticationForm.verifying_secret'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    </Fragment>)}
                </form>
            </div>
        );
    }

    render() {
        return <div className="EditUserTwoFactorAuthenticationForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditUserTwoFactorAuthenticationForm);
