import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import Moment from 'moment';
import './UserTable.css';
import Dialog from 'react-bootstrap-dialog';
import config from '../config';
import axios from 'axios';
import TooltipButton from './TooltipButton';
import TooltipLabel from './TooltipLabel';

class UserTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(user) {
        this.props.onDelete(user);
    }

    handleDeleteClicked(user) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(user),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
        
    }

    didConfirmDisable2FA(user) {
        // Get the password and token values from the inputs via refs
        const password = this.disable2FAPassword.value;
        const token = this.disable2FAToken.value;
        
        this.props.onDisable2FA(user, password, token);
    }
  
    handleDisable2FAClicked(user) {
        const { t } = this.props;
        
        this.dialog.show({
        title: t('Dialog.disable2FA'),
        // Render the form in the body of the dialog
        body: (
            <div>
            <div className="form-group">
                <label>{t('Dialog.password')}</label>
                <input
                type="password"
                ref={(input) => { this.disable2FAPassword = input; }}
                className="form-control"
                placeholder={t('Dialog.enterPassword')}
                />
            </div>
            <div className="form-group">
                <label>{t('Dialog.token')}</label>
                <input
                type="text"
                ref={(input) => { this.disable2FAToken = input; }}
                className="form-control"
                placeholder={t('Dialog.enterToken')}
                />
            </div>
            </div>
        ),
        actions: [
            Dialog.Action(
            t('Dialog.cancel'),
            () => this.dialog.hide(),
            'btn-default'
            ),
            Dialog.Action(
            t('Dialog.disable'),
            () => this.didConfirmDisable2FA(user),
            'btn-danger'
            )
        ],
        bsSize: 'large',
        onHide: dialog => {
            dialog.hide();
        }
        });
    }

    render() {
        const { t } = this.props;

        let userRows = this.props.users.map((user, index) => {

            let userRoles = null
            if(user.prettyOrganisationRoles) {
                userRoles = user.prettyOrganisationRoles.map((role, roleIndex) => {

                    let labelClass = 'label-default';

                    switch (role.id) {
                        case 1:
                            labelClass = 'label-danger';
                            break;
                        case 2:
                            labelClass = 'label-warning';
                            break;
                        case 3:
                            labelClass = 'label-primary';
                            break;
                        case 4:
                            labelClass = 'label-success';
                            break;
                        case 4:
                            labelClass = 'label-info';
                            break;
                        default:
                            break;

                    }

                    return (<TooltipLabel
                        id={role.id}
                        tooltip={role.name}
                        className={labelClass}
                        label={role.organisation_name}
                        />);
                });
            }

            return (
                <tr key={index}>
                    <td>
                        <span className="label label-success">
                            {t('UsersTable.active')}
                        </span>
                    </td>
                    <td className="hidden-xs hidden-sm">{user.firstName}</td>
                    <td className="hidden-xs hidden-sm">{user.lastName}</td>
                    <td className="hidden-xs hidden-sm" style={{ 'max-width': '250px', 'word-wrap': 'break-word'
 }}>
                        {userRoles}
                    </td>
                    <td>{user.email}</td>
                    <td className="hidden-xs hidden-sm">
                        {(user.lastLogin &&
                            Moment(user.lastLogin).format(
                                'YYYY-MM-DD HH:mm'
                            )) ||
                            '-'}
                    </td>
                    <td className="hidden-xs">
                        {Moment(user.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {Moment(user.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td align="center">
                        {this.props.userIsAuthorized(this.props.user, [
                            'staff_super_admin'
                        ]) ? (<>
                            <TooltipButton
                                id="deleteButton"
                                tooltip={t('UsersTable.deleteuser')}
                                className="btn-danger pull-right"
                                onClick={() => this.handleDeleteClicked(user)}
                            >
                                <em className="fa fa-trash" />
                            </TooltipButton>
                            {user.TwoFAEnabled && (
                              <TooltipButton
                                  id="disable2FA"
                                  tooltip={t('UsersTable.disable2FA')}
                                  className="btn-warning"
                                  onClick={() => this.handleDisable2FAClicked(user)}
                              >
                                    <em className="fa fa-key" />
                               </TooltipButton> )}
                            </>
                        ) : null}
                    </td>
                </tr>
            );
        });

        return (
            <div id="users">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />

                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('UsersTable.active')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('UsersTable.first_name')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('UsersTable.last_name')}
                            </th>
                            <th className="hidden-xs hidden-sm" style={{ 'max-width': '250px', 'word-wrap': 'break-word' }}>
                                {t('UsersTable.organisation_roles')}
                            </th>
                            <th>{t('UsersTable.email')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('UsersTable.last_login')}
                            </th>
                            <th className="hidden-xs">
                                {t('UsersTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('UsersTable.created')}
                            </th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{userRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(UserTable);
