import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import TooltipButton from './TooltipButton';
import TooltipLink from './TooltipLink';
import './OrganisationMediaLibrariesTable.css';

Moment.locale('en');

class OrganisationMediaLibrariesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(medialibrary) {
        this.props.onDelete(medialibrary);
    }

    didConfirmLock(medialibrary, locked) {
        this.props.onLockChanged(medialibrary, locked);
    }

    didSaveChanges(medialibrary, newName) {
        this.props.onSave(medialibrary, newName);
    }

    handleDeleteClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(medialibrary),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    handleLockClicked(medialibrary) {
        const { t } = this.props;

        const body = medialibrary.locked ? t('Dialog.sure_unlock') : t('Dialog.sure_lock');
        const confirm = medialibrary.locked ? t('Dialog.unlock') : t('Dialog.lock');

        this.dialog.show({
            title: t('Dialog.warning'),
            body: body,
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    confirm,
                    () => this.didConfirmLock(medialibrary, !medialibrary.locked),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    handleEditClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            body: t('OrganisationMediaLibrariesTable.medialibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('OrganisationMediaLibrariesTable.name'),
                initialValue: medialibrary.name
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.save'),
                    dialog => this.didSaveChanges(medialibrary, dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid } = this.props;
        let medialibraryRows = this.props.medialibraries.map(
            (medialibrary, index) => {
                return (
                    <tr key={index}>
                        <td>
                        {this.props.userIsAuthorized(this.props.user, [
                        'staff_super_admin',
                        'staff_admin'
                    ]) || !medialibrary.locked ? (
                        <Link
                            style={{
                                alignItems: "center",
                                textAlign: "left"
                            }}
                            className="label label-primary"
                            to={`/organisations/${uuid}/medialibraries/${medialibrary.uuid}?rel=library`}
                        >
                            <i className={'fa fa-images'} />{' '}
                            {medialibrary.name}{' '}
                        </Link>
                    ) : (
                        <><i className={'fa fa-images'} />{' '}
                            {medialibrary.name}{' '}</>)}
                        </td>{' '}
                        <td className="hidden-xs">
                            {' '}
                            {Moment(medialibrary.updatedAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}{' '}
                        </td>{' '}
                        <td className="hidden-xs">
                            {' '}
                            {Moment(medialibrary.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                            )}{' '}
                        </td>{' '}
                        <td align="center">
                        {(!medialibrary.locked ||
                            this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                            ])) && (
                            <>
                                <TooltipButton
                                className={medialibrary.hasRules ? 'btn-primary' : 'btn-default'}
                                onClick={e => {
                                    e.stopPropagation();
                                    this.props.onRulesClicked(medialibrary);
                                }}
                                tooltip={t('MediaTable.rules')}
                                >
                                <em className="fas fa-clipboard-list" />
                                </TooltipButton>
                                <TooltipButton
                                id="editButton"
                                className="btn-default pull-left"
                                onClick={() => this.handleEditClicked(medialibrary)}
                                tooltip={t('OrganisationMediaLibrariesTable.editmedialibrary')}
                                >
                                <em className="fas fa-pencil-alt" />
                                </TooltipButton>{' '}
                                {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin',
                                'organisation_user',
                                ]) && (
                                <TooltipButton
                                    id="deleteButton"
                                    className="btn-danger pull-right"
                                    tooltip={t('OrganisationMediaLibrariesTable.deletemedialibrary')}
                                    onClick={() => this.handleDeleteClicked(medialibrary)}
                                >
                                    <em className="fa fa-trash" />
                                </TooltipButton>
                                )}
                                {this.props.userIsAuthorized(this.props.user, [
                                'staff_super_admin',
                                'staff_admin',
                                ]) && (
                                <TooltipButton
                                    id="lockedButton"
                                    className={medialibrary.locked ? 'btn-warning' : 'btn-default'}
                                    tooltip={
                                        medialibrary.locked
                                            ? t('OrganisationMediaLibrariesTable.unlockmedialibrary')
                                            : t('OrganisationMediaLibrariesTable.lockmedialibrary')
                                    }
                                    disabled={this.props.lockedGlobals}  // Disable the button if organisation.lockedGlobals is true
                                    onClick={
                                        this.props.lockedGlobals 
                                            ? null 
                                            : () => this.handleLockClicked(medialibrary)
                                    }
                                >
                                    <em className={medialibrary.locked ? 'fa fa-lock' : 'fa fa-unlock'} />
                                </TooltipButton>
                                )}
                            </>
                            )}

                        </td>
                    </tr>
                );
            }
        );

        return (
            <div
                id="OrganisationMediaLibrariesTable"
                className="OrganisationMediaLibrariesTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />{' '}
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>
                                {' '}
                                {t('OrganisationMediaLibrariesTable.name')}{' '}
                            </th>{' '}
                            <th className="hidden-xs">
                                {' '}
                                {t(
                                    'OrganisationMediaLibrariesTable.updated'
                                )}{' '}
                            </th>{' '}
                            <th className="hidden-xs">
                                {' '}
                                {t(
                                    'OrganisationMediaLibrariesTable.created'
                                )}{' '}
                            </th>{' '}
                            <th width='250'>
                                <em className="fa fa-cog" />
                            </th>{' '}
                        </tr>{' '}
                    </thead>{' '}
                    <tbody> {medialibraryRows} </tbody>{' '}
                </Table>{' '}
            </div>
        );
    }
}

export default translate('translations')(OrganisationMediaLibrariesTable);
