import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';

import './CreateOrganisationForm.css';

class CreateOrganisationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newOrganisation: null,
            isLoading: false,
            name: '',
            phone: '',
            email: '',
            address: '',
            city: '',
            county: '',
            postcode: '',
            country: '',
            planType: "PREMIUM",
            userAdministered: true,
            twoFactorRequired: false,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.name.length > 0 &&
            this.state.email.length > 0 &&
            this.state.address.length > 0 &&
            this.state.country.length > 0
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleUserAdministeredChanged = event => {
        this.setState({
            userAdministered: event.target.checked
        });
    };

    handleTwoFactorRequiredChanged = event => {
        this.setState({
            twoFactorRequired: event.target.checked
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/organisations`, {
                    name: this.state.name,
                    country: this.state.country,
                    address: this.state.address,
                    city: this.state.city,
                    county: this.state.county,
                    postcode: this.state.postcode,
                    email: this.state.email,
                    phone: this.state.phone,
                    userAdministered: this.state.userAdministered,
                    planType: this.state.planType,
                    twoFactorRequired: this.state.twoFactorRequired
                })
                .then(function(response) {
                    self.setState({
                        newOrganisation: response.data.organisation,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOrganisationForm.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateOrganisationForm.created_message'
                            )}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('CreateOrganisationForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('CreateOrganisationForm.error')}:`,
                    style: 'danger',
                    message: `${t('CreateOrganisationForm.unknown_error')}`
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="createOrganisationForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t(
                                        'CreateOrganisationForm.organisation_name'
                                    )}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="phone" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateOrganisationForm.phone')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-phone" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.phone}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="email" bsSize="sm">
                                <ControlLabel>
                                    {t('CreateOrganisationForm.email')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-envelope" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="address" bsSize="sm">
                                <label>
                                    {t('CreateOrganisationForm.address')}
                                </label>
                                <FormControl
                                    type="address"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="city" bsSize="sm">
                                <label>
                                    {t('CreateOrganisationForm.city')}
                                </label>
                                <FormControl
                                    type="address"
                                    value={this.state.city}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="county" bsSize="sm">
                                <label>
                                    {t('CreateOrganisationForm.county')}
                                </label>
                                <FormControl
                                    type="county"
                                    value={this.state.county}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="postcode" bsSize="sm">
                                <label>
                                    {t('CreateOrganisationForm.post_code')}
                                </label>
                                <FormControl
                                    type="postcode"
                                    value={this.state.postcode}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="country" bsSize="sm">
                                <label>
                                    {t('CreateOrganisationForm.country')}
                                </label>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.country}
                                    onChange={this.handleChange}
                                >
                                    <option value="Ireland">
                                        {t(
                                            'CreateOrganisationForm.countries.ireland'
                                        )}
                                    </option>
                                    <option value="Spain">
                                        {t(
                                            'CreateOrganisationForm.countries.spain'
                                        )}
                                    </option>
                                    <option value="United Kingdom" defaultValue>
                                        {t(
                                            'CreateOrganisationForm.countries.uk'
                                        )}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Row bsClass="form-row">
                        <Col sm={12}>
                        <FormGroup controlId="planType" bsSize="sm">
                        <label>
                                    {t('CreateOrganisationForm.planType')}
                                </label>
                        <       FormControl
                                    componentClass="select"
                                    placeholder="Choose..."
                                    value={this.state.planType}
                                    onChange={this.handleChange}
                                >
                                    <option value="PREMIUM">
                                        {t(
                                            'CreateOrganisationForm.planTypes.premium'
                                        )}
                                    </option>
                                    <option value="LITE">
                                        {t(
                                            'CreateOrganisationForm.planTypes.lite'
                                        )}
                                    </option>
                                </FormControl>
                                <small className="form-text text-muted">
                                    {t('CreateOrganisationForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="checkbox" bsSize="sm">
                                <Checkbox
                                    checked={this.state.userAdministered}
                                    onChange={
                                        this.handleUserAdministeredChanged
                                    }
                                >
                                    {t(
                                        'CreateOrganisationForm.will_administer'
                                    )}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="checkbox" bsSize="sm">
                                <Checkbox
                                    checked={this.state.twoFactorRequired}
                                    onChange={
                                        this.handleTwoFactorRequiredChanged
                                    }
                                >
                                    {t(
                                        'CreateOrganisationForm.two_factor_required'
                                    )}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t(
                                        'CreateOrganisationForm.create_organisation'
                                    )}`}
                                    loadingText={`${t(
                                        'CreateOrganisationForm.creating_organisation'
                                    )}`}
                                />
                                <small className="text-muted">
                                    {t(
                                        'CreateOrganisationForm.create_option_explaination'
                                    )}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="CreateOrganisationForm">
                {this.state.newOrganisation === null
                    ? this.renderForm()
                    : this.renderSuccessMessage()}
            </div>
        );
    }
}

export default translate('translations')(CreateOrganisationForm);
