import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import OrganisationMediaLibrariesTable from '../components/OrganisationMediaLibrariesTable';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import DismissableAlert from '../components/DismissableAlert';
import qs from 'qs';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

import './OrganisationMediaLibraries.css';

class OrganisationMediaLibraries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            lockedGlobals: false,
            userIsAuthorized: props.userIsAuthorized,
            medialibraries: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.loadOrganisationMediaLibrariesFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name,
                    lockedGlobals: response.data.organisation.lockedGlobals
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    saveMediaLibrary(medialibrary, newName) {
        var self = this;
        axios
            .put(`${config.baseURL}/medialibraries/${medialibrary.uuid}`, {
                name: newName
            })
            .then(function(response) {
                self.loadOrganisationMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    deleteMediaLibrary(medialibrary) {
        var self = this;
        axios
            .delete(`${config.baseURL}/medialibraries/${medialibrary.uuid}`)
            .then(function(response) {
                self.loadOrganisationMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    lockMediaLibrary(medialibrary, enabled) {
        var self = this;
        axios
            .post(`${config.baseURL}/medialibraries/${medialibrary.uuid}/locked`, {
                locked: enabled
            })
            .then(function(response) {
                self.loadOrganisationMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    mediaLibraryRulesClicked(mediaLibrary) {
		const { uuid } = this.props.match.params;

		const parsed = qs.parse(this.props.location.search.substring(1));
		const rel = parsed.rel;

		let link = `/organisations/${uuid}/medialibraries/${mediaLibrary.uuid}/rules?rel=library`;

		this.props.history.push(link);
	}

    loadOrganisationMediaLibrariesFromServer() {
        var self = this;
        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}/medialibraries`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    medialibraries: response.data.mediaLibraries,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url);

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadOrganisationMediaLibrariesFromServer();
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');
        }

        window.history.pushState(null, null, url);

        event.preventDefault();
        this.loadOrganisationMediaLibrariesFromServer();
    };

    handleCreateClicked() {
        const { t } = this.props;

        this.dialog.show({
            body: t('OrganisationMediaLibrariesTable.medialibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('OrganisationMediaLibrariesTable.name'),
                initialValue: ''
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('MediaSnippet.add_medialibrary'),
                    dialog => this.didClickAdd(dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    didClickAdd(newName) {
        const { t } = this.props;
        const { uuid } = this.props.match.params;

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${uuid}/medialibraries`,
                    {
                        name: newName,
                        global: true
                    }
                )

                .then(function(response) {
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateMediaLibrary.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateMediaLibrary.created_message'
                            )}`
                        }
                    });

                    self.loadOrganisationMediaLibrariesFromServer();
                })
                .catch(function(err) {
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateMediaLibrary.error')}:`,
                            style: 'danger',
                            message: err.response.data.message || 'Unknown'
                        }
                    });
                });
        } catch (e) {
            this.setState({
                error: {
                    hidden: false,
                    title: `${t('CreateMediaLibrary.error')}:`,
                    style: 'danger',
                    message: `${t('CreateMediaLibrary.unknown_error')}`
                }
            });
        }
    }

    didConfirmLock(locked) {

        const { uuid } = this.props.match.params;

        var self = this;
        axios
            .post(`${config.baseURL}/organisations/${uuid}/lockedglobals`, {
                locked
            })
            .then(function(response) {
                self.loadOrganisationInformation();
                self.loadOrganisationMediaLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }
    
    handleLockClicked() {
            const { t } = this.props;
    
            const locked = this.state.lockedGlobals;
            const body = locked ? t('Dialog.sure_unlock_global') : t('Dialog.sure_lock_global');
            const confirm = locked ? t('Dialog.unlock') : t('Dialog.lock');
    
            this.dialog.show({
                title: t('Dialog.warning'),
                body: body,
                actions: [
                    Dialog.Action(
                        t('Dialog.cancel'),
                        () => console.log('Cancel!'),
                        'btn-default'
                    ),
                    Dialog.Action(
                        confirm,
                        () => this.didConfirmLock(!locked),
                        'btn-danger'
                    )
                ],
                bsSize: 'large',
                onHide: dialog => {
                    dialog.hide();
                }
            });
        }
        
    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'OrganisationMediaLibraries.title'
        )}`;

        const user = this.state.user;
        const { uuid } = this.props.match.params;

        const allowedAddRoles = this.state.lockedGlobals
                ? ['staff_super_admin', 'staff_admin']
                : ['staff_super_admin', 'staff_admin', 'organisation_admin', 'organisation_user'];

        return (
            <div className="OrganisationMediaLibraries">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('OrganisationMediaLibraries.title')}{' '}
                                    <small>
                                        {this.state.organisation_name}
                                    </small>
                                    
                                    {this.state.userIsAuthorized(user, allowedAddRoles) ? (
                                        <Button
                                            className="btn-primary header-button"
                                            onClick={() => this.handleCreateClicked()}
                                        >
                                            <em className="fa fa-plus fa-fw" /> {t('MediaSnippet.add_medialibrary')}
                                        </Button>
                                    ) : null}

                                    {this.state.userIsAuthorized(user, ['staff_super_admin']) ? (
                                        <Button
                                            className="btn-warning header-button"
                                            onClick={() => this.handleLockClicked()}
                                        >
                                            <em className={this.state.lockedGlobals ? 'fa fa-lock' : 'fa fa-unlock'}/> {this.state.lockedGlobals ? t('MediaSnippet.unlock_medialibraries') : t('MediaSnippet.lock_medialibraries')}

                                        </Button>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>

                                    <li className="active">
                                        <i className="fa fa-images" />{' '}
                                        {t(
                                            'SideNavigation.globalmedialibraries'
                                        )}
                                    </li>
                                </ol>

                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <OrganisationMediaLibrariesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        medialibraries={
                                            this.state.medialibraries
                                        }
                                        lockedGlobals={this.state.lockedGlobals}
                                        uuid={uuid}
                                        onSave={this.saveMediaLibrary.bind(
                                            this
                                        )}
                                        onDelete={this.deleteMediaLibrary.bind(
                                            this
                                        )}
                                        onLockChanged={this.lockMediaLibrary.bind(
                                            this
                                        )}
                                        onRulesClicked={this.mediaLibraryRulesClicked.bind(this)}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(OrganisationMediaLibraries);
